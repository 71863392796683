import React from 'react';
import { Circle } from 'better-react-spinkit';

import './styles.css';

const mainLoader = (
  <div className="spinner-outer" style={{ height: 'auto', marginTop: 200 }}>
    <Circle
      scaleStart={0.4}
      scaleEnd={0.7}
      size={120}
      color="#4A4A4A"
    />
  </div>
);

export default mainLoader;
