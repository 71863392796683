import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import mainLoader from './components/mainLoader/index';

function App() {
  return (
     <HashRouter>
          <React.Suspense fallback={mainLoader}>
            <Switch>
              <Route path="/" name="Home" render={props => <HomeScreen {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
  );
}

export default App;
