import './styles.css';
import './media.css';
import payqinLogo from '../../assets/images/PayQinlogo.png';
import franceFlag from '../../assets/images/flags/197560.svg';
import gbFlag from '../../assets/images/flags/197374.svg';
import banner from '../../assets/images/new-banner.jpg';
import avatar from '../../assets/images/avatar.jpg'
import gift from '../../assets/images/gift.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap';
import { compose, withState, withHandlers, lifecycle, componentDidMount } from 'recompose'
import PropTypes from 'prop-types'
import { getTopCustomer } from '../../api/getTopCustomer'
import moment from 'moment'
import Loader from '../../components/Loader'
import Particles from 'react-particles-js'

const HomeScreen = ({
  users,
  setUsers,
  getUsers,
  loading,
  setLoading
}) => {

const getPicture = (picture) => {
  switch (picture) {
    case '':
      return avatar;
    case null:
      return avatar;
    case undefined:
      return avatar;
   default:
      return picture;
  }
}

return (
  <>
    <header>
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
         <a className="navbar-brand" href="https://payqin.com">
         <img src={payqinLogo} alt="PayQin Logo" />
         </a> 
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com/">PARTICULIER <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com/#/developpers">BUSINESS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com/#/developpers">API</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com/#/pricing">TARIFS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com">
                <img height="20" width="20" src={franceFlag} />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://payqin.com/">
                <img height="20" width="20" src={gbFlag} />
              </a>
            </li>
          </ul>
            
            <a className="btn btn-primary my-2 my-sm-0 mt-2 mt-md-0 login-button" href="https://app.payqin.com">CONNEXION</a>
        </div>
      </nav>
    </header>
    <div className="image-container">
      <img src={banner} alt="banner" className="banner"/>
    </div>

      <div className="container mt-5">
    <div className="main-bloc">
        <div className="row">
          <div className="col-lg-6">           
              <div className="card">
                  <div className="card-body">
                          <div className="title-box mb-2">
                            <h5>L'heureux gagnant de Juin 2021</h5>
                            {/* {moment().local('fr').format('MMMM YYYY')} */}
                            {/* <h5 className="text-center font-weight-bold">Principe du jeu</h5> */}
                          </div>
                          { 
                          loading ?
                          <Loader /> 
                          :
                           <div className="content-box mt-3">
                                  <div className="row">
                                        <div className="col-lg-7 col-7">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <img src={'https://res.cloudinary.com/dx4ln3xb4/image/upload/w_150,h_150,c_thumb,g_face/nl6eix9g8clhkfnfj1on.jpg'} alt="avatar"/> 
                                                    {/* users.length > 0 ? users[0].picture : avatar */}
                                                </div>
                                                <div className="col-lg-8">    
                                                  <div className="bg-success text-white img-thumbnail leader-bloc">
                                                    Top Leader
                                                  </div>
                                                  <div className="winner-name font-weight-bold">Boubacar Cissé</div>
                                                  {/* {users.length > 0 && users[0].fullName} */}
                                                </div>  
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-5 col-5 text-right gift-img-section "> 
                                            <img src={gift} alt="gift" />
                                        </div>
                                    </div>
                                    <p className="mt-4 congratulations-section">Félicitations Boubacar Cissé! vous avez gagné un bon gratuit de PayQin. Vous êtes l'heureux gagnant du mois. Nous sommes heureux 
                                    de vous informer que vous pouvez utiliser votre bon à tout moment. Si vous avez besoin d'aide contactez notre service client.</p>
                                    {/* <p className="congratulations-section">Vous avez désormais la possibilité de devenir un ambassadeur PayQin et gagner 100 dollars. <br/> Le principe est simple:
 parrainez le maximum de personnes: en partageant votre code parrainage avec vos amis et en les invitant à acheter une carte Visa PayQin. Pour chaque carte achetée avec votre code vous gagnez 1500 francs.
 assurez-vous d’être en tête de liste dans le classement des ambassadeurs: chaque parrainage vous donne droit à 10 points, celui ou celle  qui cumulera le plus de points au 31 décembre 2020 à 23h 59 min 59 secondes, sera l’heureux gagnant et deviendra l’ambassadeur du mois.
 remportez les 100 dollars.
Chaque mois les compteurs seront remis à 0 et vous aurez encore la chance de gagner.</p>  */}
                                    <hr />
                                    <div className="box-footer">
                                        <div className="row">
                                          <div className="col-lg-9">
                                            <span className="font-weight-bold box-footer-title">
                                              Toutes nos félicitations! bon gratuit pour vous Boubacar Cissé! 
                                              {/* {users.length > 0 && users[0].fullName} */}
                                            </span>
                                          </div>
                                          <div className="col-lg-3">
                                            <span className="font-weight-bold box-footer-money text-success">
                                              $100
                                            </span>
                                          </div>
                                        </div>
                                    </div>
                                </div>  } 
                          </div>  
                  </div>  
                  <div className="mt-4 point-section mb-4">
                 <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6 col-6">
                              <img className="img-point-section" src={payqinLogo} alt="PayQin Logo" /> 
                            </div>
                            <div className="col-lg-6 col-6">
                                <div className="text-right">
                                    <span className="btn btn-outline-primary btn-sm">+10 Points</span>
                                </div>
                            </div>
                        </div>
                        <p className="mt-4">Continuez à dépenser sur PayQin et gagnez plus de points à chaque transaction.</p>

                        <div className="row">
                            <div className="col-lg-6 col-6">
                              <h4>Suivez Nous</h4>
                            </div>
                            <div className="col-lg-6 col-6">
                                <div className="text-right">
                                    <span className="btn btn-outline-primary btn-sm">+10 Points</span>
                                </div>
                            </div>
                        </div>
                        <p className="mt-4">Ses liens ont été créer pour vous. Suivez nous sur nos médias sociaux et gagnez plus de points.</p>
                        <div className="row">
                            <div className="col-lg-4">
                              <a href="https://www.facebook.com/payqin/?ref=br_rs" className="facebook-share-link btn text-white">
                                <i className="fa fa-facebook"></i> Facebook
                              </a>
                            </div>
                            <div className="col-lg-2">
                              <a href="https://twitter.com/payqinn?lang=fr" className="twitter-share-link btn btn-light text-white">
                                <i className="fa fa-twitter"></i>
                              </a>
                            </div>
                            <div className="col-lg-2">
                              <a href="https://www.instagram.com/payqin/" className="instagram-share-link btn btn-light text-white">
                                <i className="fa fa-instagram"></i>
                              </a>
                            </div>
                            <div className="col-lg-2">
                              <a href="https://www.linkedin.com/company/afrostarter" className="linkedin-share-link btn btn-light text-white">
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </div>
                            <div className="col-lg-2">
                              <a href="#" className="google-share-link btn btn-light text-white">
                                <i className="fa fa-google-plus"></i>
                              </a>
                            </div>
                        </div>
                    </div>
                </div>  
              </div>             
              </div>
            
            
            <div className="col-lg-6">
              <div className="card">
                  <div className="card-body">
                          <div className="title-box-top-10 mb-4 text-center">
                            <h5>Top 10 des Leaders du mois ({moment().local('fr').format('MMMM YYYY')}) </h5>
                          </div>
                          <div className="content-box mt-3">
                               <div className="container">
                                    {/* <div className="scare-ranking container">
                                        <div className="row">
                                          <div className="col-lg-2 col-2"> 
                                              <span className="text-white ranking-number">{users.length > 0 && '#' +  (users.indexOf(users[0])+1)}</span>
                                          </div>
                                          <div className="col-lg-2 col-2">
                                            <img className="avatar" src={users.length > 0 ? getPicture(users[0].picture) : avatar} alt="avatar"/> 
                                          </div>
                                          <div className="col-lg-4 col-4 text-white fullname-ranking">
                                              {users.length > 0 && users[0].fullName}
                                          </div>
                                          <div className="col-lg-4 col-4 text-white point-ranking">
                                              {users.length > 0 && users[0].totalInvites*10 + ' Points'}
                                          </div>
                                          
                                        </div>
                                    </div> */}

                                   { 
                                   loading ?
                                   <Loader />
                                   :
                                     users.length > 0 && users.map((user, index) =>
                                      <div 
                                      key={index} 
                                      className={'scare-ranking-white-bg container card mt-3'}
                                      style={{background: users.indexOf(user) === 0 && '#7577FA'}}>
                                        <div className="row">
                                          <div className="col-lg-2 col-6"> 
                                              <span style={{ color: users.indexOf(user) === 0 && '#fff' }} className="ranking-number">{'#' + (users.indexOf(user)+1)}</span>
                                          </div>
                                          <div className="col-lg-2 col-6">
                                            <img className="avatar" src={getPicture(user.picture)} alt="avatar"/> 
                                          </div>
                                          <div style={{ textTransform: 'capitalize', color: users.indexOf(user) === 0 && '#fff' }} className="col-lg-4 col-6 fullname-ranking">
                                              {user.fullName}
                                          </div>
                                          <div style={{ color: users.indexOf(user) === 0 && '#fff' }} className="col-lg-4 col-6 point-ranking">
                                              {user.totalInvites*10 + ' Points'}
                                          </div>
                                          
                                        </div>
                                    </div>
                                     )
                                    }
                               </div>   
                          </div>         
                  </div>
            </div>
        </div>
  
    </div>
</div>
</div> <br/>

 <footer className="footer mt-auto py-3">
  <div className="container">
    <div className="row">
      <div className="col-lg-9">
          <div className="row">
            <div className="m-1">
              <a className="text-dark footer-link nav-link footer-label" href="https://payqin.com/about/">A propos</a>
            </div>
            <div className="m-1">
              <a className="text-dark footer-link nav-link footer-label" href="https://blog.payqin.com/">Blog</a>
            </div>
            <div className="m-1">
              <a className="text-dark footer-link nav-link footer-label" href="https://payqin.crisp.help/">Aide</a>
            </div>
            <div className="m-1">
              <a className="text-dark footer-link nav-link footer-label" href="https://payqin.com/mentions-legales/">Mentions Légales</a>
            </div>
          </div>
          
      </div>
      <div className="col-lg-3">
        <div className="row">
          <div className="m-1">
            <a className="text-dark footer-link nav-link footer-label fa fa-facebook" href="https://www.facebook.com/payqin/?ref=br_rs" rel="noreferrer" target="_blank"></a>
          </div>
          <div className="m-1">
            <a className="text-dark footer-link nav-link footer-label fa fa-instagram" href="https://www.instagram.com/payqin/" rel="noreferrer" target="_blank"></a>
          </div>
          <div className="m-1">
            <a href="https://payqin.com/contact" className="btn btn-light btn-contact-footer">CONTACT US</a>
          </div>
        
        </div>
      </div>
    </div>

    <div className="row p-3">
      <div>
          <img src={payqinLogo} alt="logo" />
      </div>
      <div className="ml-2">
        <small className="footer-label">&copy; PayQin Ltd.</small>
      </div>
    </div>
  </div>
</footer>
</>

)
}

HomeScreen.propTypes = {
  users: PropTypes.array.isRequired,
  setUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired
}

export default compose(
    withState('users', 'setUsers', []),
    withState('loading', 'setLoading', true),
    withHandlers({
      getUsers: ({
        setUsers,
        setLoading
      }) => async () => {  
        console.log(moment.locale)
        const res = await getTopCustomer()
        if(res.success) {
          setLoading(false)
          setUsers(res.data)          
        }
      }
    }),
    lifecycle({
      componentDidMount() {
        this.props.getUsers()
      }
    })
)(HomeScreen)