import host from './host'
import I18n from 'i18n-js'

export async function getTopCustomer ()
{
    try {

     const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': I18n.currentLocale(),
            'version': 999
        }
    };

        const response = await fetch(`${host}/users/challenge/top-referral`, requestOptions)
        return response.json()
    }
    catch (error) {
        return console.log(error)
    }
}

